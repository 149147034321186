import { createContext } from "react";

export const MediaContext = createContext(false);

function MediaProvider({ children, value = false }) {
  return (
    <MediaContext.Provider value={value}>{children}</MediaContext.Provider>
  );
}

export default MediaProvider;
